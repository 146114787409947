import { computed, Ref, ref } from 'vue'
import { defineStore } from 'pinia'
import { BaseError, objectify } from '@courseur/utils'
import { useEzioAPI } from '@courseur/services'
import { Alert } from './type'
import { useEzioWs } from '@courseur/services'
import { localStores } from '../'

export const useAlertsStore = defineStore('alerts', () => {
    const { api } = useEzioAPI()
    const { ws } = useEzioWs()

    const all: Ref<{ [id: string]: Alert }> = ref({})
    const threatAlerts: Ref<string[]> = ref([])
    const warningAlerts: Ref<string[]> = ref([])

    const getWarningAlerts = computed((): Alert[] => {
        return warningAlerts.value
            .map((id) => {
                return all.value[id]
            })
            .sort((a, b) => {
                return a.createdAt.localeCompare(b.createdAt)
            })
            .sort((a, b) => a.status.localeCompare(b.status))
    })

    const getThreatAlerts = computed((): Alert[] | null => {
        return threatAlerts.value.length > 0 ? threatAlerts.value.map((id) => all.value[id]) : null
    })

    const getUnreadNumber = computed((): number => {
        return getWarningAlerts.value.filter((alert) => {
            !isRead(alert?.createdAt)
        }).length
    })

    const init = () => {
        ws.on('alert:new', async (payload: Alert) => {
            console.log('nouveau message', payload)
            await fetch(payload.id)
        })
    }

    const fetchCollection = async () => {
        try {
            if (!localStores.currentStore?.user?.agencyId) return
            const { data: response } = await api.get('alerts/', { agency_id: localStores.currentStore.user.agencyId })
            warningAlerts.value = response.filter((alert) => alert.alertType === 'warning').map((notif) => notif.id)
            threatAlerts.value = response.filter((alert) => alert.alertType === 'threat').map((notif) => notif.id)
            store(response)
        } catch (e) {
            throw new BaseError({
                title: 'Oups !',
                message: e.message
            })
        }
    }
    const isRead = (createdAt: string): boolean => new Date(localStores.currentStore.user.notificationsSeenAt).getTime() > new Date(createdAt).getTime()

    const fetch = async (alertId: string) => {
        try {
            const { data: alert } = await api.get('alerts/' + alertId, { agency_id: localStores.currentStore.agencyId })
            store([alert])
            alert.alertType === 'warning' ? warningAlerts.value.push(alert.id) : threatAlerts.value.push(alert.id)
            return alert
        } catch (e) {
            throw new BaseError({
                title: 'Oups !',
                message: e.message
            })
        }
    }

    const update = async (alertId: string, status: 'pending' | 'resolved') => {
        try {
            const response = await api.put('alerts/' + alertId, { status })
            store([response.data])
        } catch (e) {
            throw new BaseError({
                title: 'Oups !',
                message: e.message
            })
        }
    }

    const store = (alerts: Alert[]) => {
        all.value = Object.assign({}, all.value, objectify(alerts))
    }

    const reset = () => {
        all.value = {}
    }

    return {
        warningAlerts: getWarningAlerts,
        unreadNumber: getUnreadNumber,
        threatAlerts: getThreatAlerts,
        isRead,
        init,
        fetchCollection,
        fetch,
        update,
        reset
    }
})
