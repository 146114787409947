import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

export const useTokensStore = defineStore('tokens', () => {
    const initialTokens: Tokens = {
        accessToken: null,
        uid: null,
        client: null,
    }
    
    const appName = import.meta.env.APP_NAME

    const tokens = useStorage(appName + '-tokens', Object.assign({}, initialTokens))

    const store = (headers: any) => {
        tokens.value = {
            accessToken: headers['access-token'],
            client: headers.client,
            uid: headers.uid,
        }
    }

    const reset = () => {
        tokens.value = initialTokens
    }

    return {
        tokens,
        storeTokens: store,
        reset,
    }
})

interface Tokens {
    accessToken: string | null
    client: string | null
    uid: string | null
}
