import { defineAsyncComponent, ref, Ref } from 'vue'

let instance: any = null

const createInstance = () => {
    const visible: Ref<boolean> = ref(false)
    const width: Ref<string> = ref('60%')
    const component = ref()
    const title: Ref<string> = ref('')
    const buttons: Ref<any[]> = ref([])
    const error: Ref<string> = ref('')
    let onDismiss: any = null

    const show = async (params: any) => {
        error.value = ''
        component.value = defineAsyncComponent(params.component)
        title.value = params.title
        buttons.value = params.buttons
        visible.value = true
        width.value = params.width || '60%'
        onDismiss = params.onDismiss
    }

    const dismiss = () => {
        onDismiss()
        visible.value = false
        component.value = null
        title.value = ''
        error.value = ''
        buttons.value = []
        onDismiss = null
    }

    return {
        buttons,
        component,
        title,
        error,
        visible,
        width,
        show,
        onDismiss,
        dismiss
    }
}

export const useModalBox = () => {
    if (instance) {
        return {
            modalBox: instance
        }
    }
    instance = createInstance()
    return {
        modalBox: instance
    }
}
