import { computed, Ref, ref } from 'vue'
import { defineStore } from 'pinia'
import { BaseError, objectify } from '@courseur/utils'
import { useEzioAPI } from '@courseur/services'
import { User } from './types'
import { localStores } from '..'

export const useUsersStore = defineStore('users', () => {
    const { api } = useEzioAPI()

    const all: Ref<{ [id: string]: User }> = ref({})
    const indexByLastname = ref<User[]>([])

    const fetchCollection = async () => {
        const response = await api.get('users', { pagination: false, is_manager: true })
        storeIndex(response.data)
        return response.data
    }

    const fetch = async (id: string) => {
        const response = await api.get('users/' + id, {
            include: 'account'
        })
        store([response.data])
    }

    const create = async (params: any) => {
        const response = await api.post('users', params)
        store([response.data])
        updateIndex(response.data)
        return response.data
    }

    const createOrUpdate = async (data: any) => {
        if (data.id) {
            return await update(data)
        } else {
            return await create(data)
        }
    }
    const update = async (data: any) => {
        console.log('update user does nothing', data)
        // updateIndex(response.data)
    }

    const notificationsOpenedAt = async () => {
        try {
            const response = await api.put('users/' + localStores.currentStore.user.id + '/notifications_seen_at')
            return response.data
        } catch (e) {
            //
        }
    }

    const updateUser = async (user: any) => {
        try {
            const response = await api.put('users/' + user.id, user)
            updateIndex(response.data)
            return response.data
        } catch (error) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message
            })
        }
    }

    const blockUser = async (userId: string, blocked: boolean) => {
        try {
            const response = await api.put('users/' + userId + '/block', { blocked })
            store([response.data])
            updateIndex(response.data)
            return response.data
        } catch (error) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message
            })
        }
    }
    const banAssociatedMandates = async (userId: string) => {
        try {
            const response = await api.post('users/' + userId + '/ban_associated_mandates')
            store([response.data])
            return response.data
        } catch (error) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message
            })
        }
    }

    const updateIndex = (user: User) => {
        const temp = indexByLastname.value.slice()
        const idx = temp.findIndex((c) => user.id === c.id)
        if (idx > -1) {
            temp[idx] = user
        } else {
            temp.push(user)
        }
        temp.sort(sortByLastname)
        indexByLastname.value = temp
    }

    const storeIndex = (users: User[]) => {
        indexByLastname.value = users.sort(sortByLastname)
    }
    const store = (users: User[]) => {
        all.value = Object.assign({}, all.value, objectify(users))
    }

    const reset = () => {
        all.value = {}
    }

    const sortByLastname = (A, B) => {
        const nameA = A.lastname
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
        const nameB = B.lastname
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')

        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }
        return 0
    }

    return {
        all,
        byLastName: indexByLastname,
        create,
        fetchCollection,
        fetch,
        createOrUpdate,
        notificationsOpenedAt,
        updateUser,
        blockUser,
        banAssociatedMandates,
        reset
    }
})
