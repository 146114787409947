import dayjs from 'dayjs'

export const objectify = (data: any[], toAdd: any = null): { [id: string]: any } => {
    const o: any = {}
    data.forEach((d) => {
        const n = Object.assign({}, d, toAdd)
        o[d.id] = n
    })
    return o
}

export const arrayfy = (data: { [id: string]: any }): any[] => {
    return Object.keys(data).map((key) => data[key])
}

export const camelCaseKeysToUnderscore = (o: any) => {
    if (typeof o != 'object' || o === null) return o

    const obj = Object.assign({}, o)
    for (const oldName in obj) {
        // Camel to underscore
        const newName = oldName.replace(/([A-Z])/g, function ($1) {
            return '_' + $1.toLowerCase()
        })

        // Only process if names are different
        if (newName != oldName) {
            // Check for the old property name to avoid a ReferenceError in strict mode.

            obj[newName] = obj[oldName]
            delete obj[oldName]
        }

        // Recursion
        if (typeof obj[newName] == 'object') {
            obj[newName] = camelCaseKeysToUnderscore(obj[newName])
        }
    }
    return obj
}

export const underscoreKeysToCamelCase = (o: any) => {
    if (typeof o != 'object' || o === null) return o

    const obj = Object.assign({}, o)
    for (const oldName in obj) {
        // Camel to underscore
        const newName = oldName.replace(/_[a-z0-9]/g, function ($1) {
            return $1.slice(-1).toUpperCase()
        })

        // Only process if names are different
        if (newName != oldName) {
            // Check for the old property name to avoid a ReferenceError in strict mode.

            obj[newName] = obj[oldName]
            delete obj[oldName]
        }

        // Recursion
        if (typeof obj[newName] == 'object') {
            obj[newName] = underscoreKeysToCamelCase(obj[newName])
        }
    }
    return obj
}

export function cloneDeep(entity: any, cache = new WeakMap()): any {
    const referenceTypes = ['Array', 'Object', 'Map', 'Set', 'Date']
    const entityType = Object.prototype.toString.call(entity)
    if (!new RegExp(referenceTypes.join('|')).test(entityType) || entity instanceof WeakMap || entity instanceof WeakSet)
        return entity
    if (cache.has(entity)) {
        return cache.get(entity)
    }
    const c = new entity.constructor()

    if (entity instanceof Map) {
        entity.forEach((value, key) => c.set(cloneDeep(key), cloneDeep(value)))
    }
    if (entity instanceof Set) {
        entity.forEach((value) => c.add(cloneDeep(value)))
    }
    if (entity instanceof Date) {
        return new Date(entity)
    }
    cache.set(entity, c)
    return Object.assign(c, ...Object.keys(entity).map((prop) => ({ [prop]: cloneDeep(entity[prop], cache) })))
}

export const monthsList = (currentMonth: string, firstMonth: string) => {
    const months = [currentMonth]
    let m = dayjs()
    while (m.format('YYYY-MM') !== firstMonth) {
        m = m.subtract(1, 'month')
        months.push(m.format('YYYY-MM'))
    }
    return months
}