<template>
    <div class="centered-content">
        <div class="centered">
            <router-view />
        </div>
    </div>
    
</template>

<script lang="ts" setup>

</script>
<style lang="scss" scoped>
.centered-content {
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: space-around;
}
</style>
