<template>
    <button v-bind="attrs" type="button">
        <font-awesome-icon icon="bell" class="notification" />
        <span v-if="unreadNumber > 0" class="badge">{{ unreadNumber }}</span>
    </button>
</template>

<script lang="ts" setup>
import { computed, useAttrs } from 'vue'
import { useAlertsStore } from '@/stores'

const attrs = useAttrs()
const unreadNumber = computed(() => useAlertsStore().unreadNumber)
</script>

<style lang="scss" scoped>
button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    color: #333;
    background: none;
    border: none;
    outline: none;
    border-radius: 50%;
    cursor: pointer;
    @media screen and (max-width: 799px) {
        display: none;
    }
    .badge {
        position: absolute;
        right: -5px;
        top: -5px;
        width: 23px;
        height: 23px;
        font-size: 0.75rem;
        background: var(--c-color-warning);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }
}
svg {
    font-size: 20px;
    color: #2c3e50;
}
</style>
