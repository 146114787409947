import { io, Socket } from 'socket.io-client'
// import { WsError } from './WsError'
// import { WsConfig } from './types/WsConfig'
// import { WsErrorParams } from './types/WsErrorParams'

import { WsConfig } from './types/WsConfig'

let socket: Socket = null

const useSocketIo = (config: WsConfig) => {

    const connect = (auth: any): Socket => {
        
        if(!socket) {
            socket = io(config.url, {
                auth
            })
        }
        return socket
    }

    return {
        connect
    }
}

export {
    useSocketIo,
}
