<template>
    <div class="modal-box">
        <div v-if="visible" class="modal-wrapper">
            <div ref="modal" class="modal">
                <div v-if="title" class="header">
                    <div class="title">
                        {{ title }}
                    </div>
                </div>
                <div class="modal-content">
                    <component :is="{ ...component }" />
                    <!-- {...component} shallow copy the component to avoid vue warning about performances -->
                </div>
                <div v-if="error">
                    {{ error }}
                </div>

                <div class="footer">
                    <div class="buttons">
                        <template v-for="(button, index) of buttons" :key="index">
                            <CButton v-if="!button.csbutton" :class="button.class" @click="button.handler()">
                                {{ button.text }}
                            </CButton>
                            <CSButton v-else :state="isSubmitting">
                                <CButton :class="button.class" @click="handle(button.handler)">{{ button.text }}</CButton>
                                <CButton class="border-spin">En cours ...</CButton>
                            </CSButton>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { useModalBox } from './modalBox'

const { modalBox } = useModalBox()
const { title, component, buttons, error, dismiss, visible, width } = modalBox

const modal = ref(null)
const isSubmitting = ref(false)

// const showOk = computed(() => props.showOk && props.closable)
// const showCancel = computed(() => props.showCancel && props.closable)

onClickOutside(modal, () => {
    dismiss()
})

const handle = async (fn: any) => {
    isSubmitting.value = true
    await fn()
    isSubmitting.value = false
}
</script>

<style lang="scss" scoped>
.modal-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
}
.modal-wrapper {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.45);
    overflow: scroll;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    display: flex;
    flex-direction: column;
    margin: auto;
    background: #fff;
    width: v-bind(width);
    border-radius: 2px;
    box-shadow:
        0px 12px 32px 4px rgba(0, 0, 0, 0.04),
        0px 8px 20px rgba(0, 0, 0, 0.08);
    // transition: all 1s ease-out;
}

.modal-content {
    padding: 30px;
}

.header {
    display: flex;
    padding: 10px 10px 20px 20px;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    border-bottom: 1px solid var(--c-color-border-soft);
    .title {
        line-height: 24px;
        font-size: 18px;
    }

    svg {
        cursor: pointer;
        padding: 10px;
        font-size: 16px;
    }
}

.close-btn {
    align-self: end;
}

.footer {
    padding: 20px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    button {
        width: 80%;
    }
}
</style>
