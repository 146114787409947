<template>
    <div class="file-upload-box">
        <Transition name="fade" mode="out-in" :duration="150">
            <div class="wrapper" v-if="visible">
                <div class="back-click" @click="dismiss" v-if="visible" />
                <div
                    class="file-input"
                    @drop.prevent.stop="dropFile"
                    @dragover.prevent="over = true"
                    @dragleave.prevent="over = false"
                >
                    <img class="file-preview" v-if="preview" :src="preview" />

                    <div v-else class="file-zone" :class="{ over: over }">
                        <label class="input" v-if="!over">
                            <div class="label">
                                Cliquez ici pour sélectionner vos fichiers ou glissez-les dans cette zone.
                            </div>
                            <input type="file" @change="onChange" :accept="accept" />
                        </label>
                        <div v-else>Déposez votre fichier ici</div>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useFileUploadBox } from '.'
import { useNProgress } from '@vueuse/integrations/useNProgress';

const { fileUploadBox } = useFileUploadBox()
const { start, done } = useNProgress()

const { accept, dismiss, onSelectFile, visible, preview } = fileUploadBox
const over = ref(false)

const onChange = async (ev: any) => {
    start()
    await onSelectFile(ev.target.files[0])
    done()
}

const dropFile = async (ev: any) => {
    start()
    await onSelectFile(ev.dataTransfer.files[0])
    over.value = false
    done()
}
</script>

<style lang="scss" scoped>
.file-upload-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
}

.back-click {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    opacity: 0;
}

.wrapper {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.55);

    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.file-input {
    position: relative;
    z-index: 1;
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
    // width: ;
    width: 60%;
    height: 60%;
}
.file-zone {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1.1rem;
    border: 4px solid var(--c-color-border-hard);
    border-radius: 10px;
    overflow: hidden;

    transition: all 0.7s;
}
.file-preview {
    // background: #444;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.45);
    display: block;
    max-height: 100%;
}

.input {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 30px;

    text-align: center;
    cursor: pointer;

    .label {
        max-width: 400px;
        margin-bottom: 30px;
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        // z-index: -1;
        opacity: 0;
    }
}
.over {
    background: var(--c-color-primary);
    color: #fff;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
