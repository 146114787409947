export const formatIban = (value: string, obfuscate = false) => {
    const val = value && obfuscate ? obfuscateIban(value) : value
    return val
        ? val
              .replace(/[^\da-zA-Z]/g, '')
              .replace(/(.{4})/g, '$1 ')
              .trim()
              .toUpperCase()
        : ''
}

export const currency = (value: number | string, symbol = '€') => {
    if (typeof value === 'string') {
        value = value.replace(',', '.').replace(' ', '')
    }
    let val = parseFloat(value as string)
    if (isNaN(val)) val = 0
    return val.toFixed(2) + ' ' + symbol
}

const obfuscateIban = (value: string) => {
    return value.substring(0, 2) + 'XX XXXX XXXX XXXX XXXX' + value.substring(value.length - 3, value.length)
}
