<template>
    <button v-bind="attrs" class="c-back-button" @click="dismiss">
        <slot>Retour</slot>
    </button>
</template>

<script lang="ts" setup>
import { useAttrs } from 'vue'
import { useRouter } from 'vue-router'

const props = defineProps<{
    src?: string
}>()

const attrs = useAttrs()

const router = useRouter()
const dismiss = () => {
    if (props.src) {
        router.push(props.src)
    }
}
</script>
<style lang="scss" scoped>
.c-back-button {
    display: flex;
    align-items: center;
    border: 0;
    font-size: 1.4em;
    color: var(--c-color-text-hard);
    background: none;
    text-align: center;
    cursor: pointer;
    outline: 0;
    &:before {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        margin-right: 5px;
        border-left: 2px solid var(--c-color-text-hard);
        border-bottom: 2px solid var(--c-color-text-hard);
        transform: rotate(45deg);
    }
}
</style>
