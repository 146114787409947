<template>
    <button v-bind="attrs" class="c-button">
        <slot />
    </button>
</template>

<script lang="ts" setup>
import { useAttrs } from 'vue'

const attrs = useAttrs()
</script>

<style lang="scss" scoped>
.c-button {
    display: block;
    padding: 10px;
    background: var(--c-color-primary);
    border: 0;
    border-radius: var(--c-border-radius);
    color: #fff;
    text-align: center;
    cursor: pointer;
    outline: 0;
    width: auto;
    // transition: width 0.5s ease-in-out !important;
}
.border {
    background: #fff;
    color: var(--c-color-primary);
    border: 1px solid var(--c-color-primary);
    &.negative {
        background: none;
        color: #fff;
        border-color: #fff;
    }
}
.simple {
    background: #fff;
    color: var(--c-color-primary);
    border: none;
    &.negative {
        background: none;
        color: #fff;
    }
}
.bare {
    background: none;
    color: var(--c-color-primary);
    border: none;
    padding: 0;
    text-align: left;
    &:hover {
        text-decoration: underline;
    }
    &.negative {
        background: none;
        color: #fff;
    }
}

.disabled,
:disabled {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.26);
    background-color: rgba(0, 0, 0, 0.12);
    border: 0;
}
.alert {
    &.border {
        color: var(--c-color-alert);
        border: 1px solid var(--c-color-alert);
    }
    &.simple {
        color: var(--c-color-alert);
    }
    &.bare {
        text-decoration: none;
        color: var(--c-color-alert);
    }
}
.warning {
    &.border {
        color: var(--c-color-warning);
        border: 1px solid var(--c-color-warning);
    }
    &.simple {
        color: var(--c-color-warning);
    }
    &.bare {
        color: var(--c-color-warning);
    }
}

.border-spin {
    position: relative;
    z-index: 2;
    overflow: hidden;
    background: #fff;
    color: var(--c-color-primary);
    &::before {
        position: absolute;
        content: '';
        left: 50%;
        top: 50%;
        width: 500px;
        height: 500px;
        -webkit-animation: rotate 1s infinite linear !important;
        animation: rotate 5s infinite linear !important;
        background: #ff6b45;
        transform-origin: 0 0;
        z-index: -1;
        @keyframes rotate {
            100% {
                transform: rotate(360deg);
            }
        }
    }
    &::after {
        content: '';
        position: absolute;
        display: grid;
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 2px;
        place-items: center;
        font-size: 10px;
        background: #fff;
        z-index: -1;
        border-radius: var(--c-border-radius);
    }
}
</style>
