import { computed, ref, Ref } from 'vue'
import { defineStore } from 'pinia'
import { useEzioAPI } from '@courseur/services'
import { BaseError, objectify } from '@courseur/utils'
import { AccountCreationParams, Organization, SellsyClient } from './types'
import { User } from '@courseur/features/users/types'

export const useOrganizationsStore = defineStore('organizations', () => {
    const { api } = useEzioAPI()

    const all: Ref<{ [id: string]: Organization }> = ref({})
    const indexByName = ref<Organization[]>([])

    const fetchCollection = async () => {
        const response = await api.get('accounts', {
            pagination: false
        })
        storeIndex(response.data)
        return response.data
    }
    const fetch = async (accountId: string) => {
        const response = await api.get('accounts/' + accountId, {
            include: 'agencies',
            pagination: false
        })
        store([response.data])
    }

    const create = async (params: AccountCreationParams) => {
        try {
            const response = await api.post('accounts', params)
            store([response.data])
            updateIndex(response.data)
            return response.data
        } catch (error: any) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message
            })
        }
    }

    const createOrUpdate = async (data: AccountCreationParams | Organization) => {
        if ('id' in data) {
            return await update(data)
        } else {
            return await create(data)
        }
    }

    const update = async (organization: any) => {
        try {
            const params = Object.assign({}, organization, { include: 'agencies' })
            const response = await api.put('accounts/' + organization.id, params)
            store([response.data])
            updateIndex(response.data)
            return response.data
        } catch (error: any) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message
            })
        }
    }

    const addSellsyClient = async (id: string, client: SellsyClient) => {
        const clients = Object.assign({}, all.value[id]?.sellsy.clients, {
            [client.id]: client
        })
        update({ id, sellsy: { clients } })
    }

    const deleteSellsyClient = async (id: string, clientId: string) => {
        const clients = Object.assign({}, all.value[id]?.sellsy.clients)
        delete clients[clientId]
        update({ id, sellsy: { clients } })
    }

    const remove = async (accountId: string) => {
        const response = await api.delete('accounts/' + accountId)
        return response.data
    }

    const addManager = async (accountId: string, params: any) => {
        const response = await api.post('accounts/' + accountId + '/managers', params)
        store([response.data])
    }

    const fetchUsers = async (accountId: string): Promise<User[]> => {
        // GET /accounts/accountId/users
        const response = await api.get('accounts/' + accountId + '/users')
        return response.data
    }

    const createUser = async (accountId: string, user: any) => {
        const response = await api.post('accounts/' + accountId + '/users', user)
        return response.data
    }

    const updateIndex = (organization: Organization) => {
        const temp = indexByName.value.slice()
        const idx = temp.findIndex((c) => organization.id === c.id)
        if (idx > -1) {
            temp[idx] = organization
        } else {
            temp.push(organization)
        }
        temp.sort(sortByName)
        indexByName.value = temp
    }

    const storeIndex = (organizations: Organization[]) => {
        indexByName.value = organizations.sort(sortByName)
    }

    const store = (organizations: Organization[]) => {
        all.value = Object.assign({}, all.value, objectify(organizations))
    }

    const reset = () => {
        all.value = {}
    }

    const sortByName = (A, B) => {
        const nameA = A.name
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')

        const nameB = B.name
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')

        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }
        return 0
    }

    return {
        all,
        byName: indexByName,
        fetchCollection,
        fetch,
        create,
        createOrUpdate,
        update,
        addSellsyClient,
        deleteSellsyClient,
        delete: remove,
        addManager,
        //
        fetchUsers,
        createUser,
        //
        reset
    }
})
