<template>
<component :is="layout">
    <slot />
</component>
</template>

 
<script lang="ts" setup>
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { params } from './plugin'

const layout = ref()
const route = useRoute()

watch( () => route.meta, async meta => {
    layout.value = meta.layout || params.defaultLayout
}) 

</script>
 