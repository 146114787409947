<template>
<div class="alert-box">
    <Transition name="fade" mode="out-in" :duration="150">
        <div class="alert-wrapper" v-if="visible">
            <div class="alert" ref="alert">
                {{ message }}

                <div class="buttons">
                    <template v-for="(button, index) of buttons" :key="index">
                        <CButton v-if="!button.csbutton" :class="button.class" @click="button.handler()">
                            {{ button.text }}
                        </CButton>
                        <CSButton v-else :state="isSubmitting">
                            <CButton :class="button.class" @click="handle(button.handler)">{{ button.text }}</CButton>
                            <CButton class="border-spin">En cours ...</CButton>
                        </CSButton>
                    </template>
                </div>
            </div>
        </div>
    </Transition>
</div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { useAlertBox } from '.'

const { alertBox } = useAlertBox()
const { message, buttons, visible } = alertBox
const alert = ref(null)

onClickOutside(alert, () => {
    buttons.value[0].handler()
})

const isSubmitting = ref(false)

const handle = async (fn: any) => {
    isSubmitting.value = true
    await fn()
    isSubmitting.value = false
}
</script>

<style lang="scss" scoped>
.alert-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
}
.alert-wrapper {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.45);
    overflow: scroll;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.alert {
    white-space: nowrap;
    text-align: center;
    background: #fff;
    padding: 30px;
    border-radius: 4px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.45);
}
.buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}
.c-button {
    margin: 0 10px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
