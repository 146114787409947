export function useBase64() {
    // const imageUrlToBlob = (url: string) => {
    //     return new Promise((r) => {
    //         const xhr = new XMLHttpRequest()
    //         xhr.onload = () => {
    //             const reader = new FileReader()
    //             reader.onloadend = () => {
    //                 r(reader.result)
    //             }
    //             reader.readAsDataURL(xhr.response)
    //         }

    //         xhr.open('GET', url)
    //         xhr.responseType = 'blob'
    //         xhr.send()
    //     })
    // }
    // const convertImageUrlRoBlob = (url: string) => {
    //
    // }

    const convertImageToB64 = async (file: Blob, maxSize = 500): Promise<string> => {
        const img = document.createElement('img')
        img.src = await new Promise<any>((r) => {
            const reader = new FileReader()
            reader.onload = (e: any) => r(e.target.result)
            reader.readAsDataURL(file)
        })
        await new Promise((r) => (img.onload = r))

        const canvas = document.createElement('canvas')
        let ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0)

        let width = img.naturalWidth
        let height = img.naturalHeight
        if (width > height) {
            if (width > maxSize) {
                height *= maxSize / width
                width = maxSize
            }
        } else {
            if (height > maxSize) {
                width *= maxSize / height
                height = maxSize
            }
        }
        canvas.width = width
        canvas.height = height
        ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0, width, height)
        // const result = await new Promise<Blob>((r) => { canvas.toBlob(r, 'image/jpeg', 0.95); });
        const result = canvas.toDataURL('image/jpeg', 0.95)
        return result
    }

    const convertFileToB64 = (file: Blob): Promise<any> => {
        return new Promise((r) => {
            const reader = new FileReader()
            reader.onload = (event) => {
                const dataURI = event.target.result
                if (dataURI) {
                    r(dataURI)
                }
            }
            reader.readAsDataURL(file)
        })
    }

    return {
        convertFileToB64,
        convertImageToB64
    }
}
