<template>
    <div class="image-box">
        <Transition name="fade" mode="out-in" :duration="150">
            <div class="wrapper" @click="dismiss" v-if="visible" :class="{ zoomed: zoomed }">
                <button @click.stop="next(-1)" v-if="images.length > 1">
                    <font-awesome-icon icon="chevron-left" />
                </button>
                <img
                    :key="images[current]"
                    :src="images[current]"
                    alt=""
                    @click.stop="zoom"
                    ref="currentImage"
                    :class="{ 'can-zoom': true }"
                />
                <button @click.stop="next(1)" v-if="images.length > 1">
                    <font-awesome-icon icon="chevron-right" />
                </button>
            </div>
        </Transition>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, Ref, watch } from 'vue'

import { useImageBox } from '.'

const zoomed: Ref<boolean> = ref(false)
const currentImage = ref(null)

const { imageBox } = useImageBox()
const { images, visible, dismiss, current } = imageBox

const zoom = () => {
    if (!zoomed.value && currentImage.value.naturalHeight > currentImage.value.height) {
        zoomed.value = !zoomed.value
        return
    }
    if (zoomed.value) {
        zoomed.value = false
        return
    }
}
const canZoom = computed(() => {
    if (!currentImage.value) {
        return false
    }
    return currentImage.value.naturalHeight > currentImage.value.height
})

const next = (value: number) => {
    current.value += value
    if (current.value > images.value.length - 1) {
        current.value = 0
    }
    if (current.value < 0) {
        current.value = images.value.length - 1
    }
}

watch(images, () => {
    zoomed.value = false
})
</script>

<style lang="scss" scoped>
.image-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
}
.wrapper {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.45);
    overflow: scroll;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
        display: block;
        max-height: 100%;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.45);
        &:only-child {
            margin: 0 auto;
        }
    }
    .can-zoom {
        cursor: zoom-in;
    }
    button {
        border: 0; //1px solid var(--c-color-primary);;
        background: #fff;
        font-size: 2rem;

        color: var(--c-color-primary);
        padding: 15px 25px;
        // margin: 20px;
        cursor: pointer;
        border-radius: 4px;
    }

    &.zoomed {
        padding: 0px 10px;
        display: block;
        img {
            max-height: none;
            max-width: 100%;
            cursor: zoom-out;
            margin: 0 auto;
        }
        button {
            display: none;
        }
    }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
