<template>
    <LayoutSystem />
    <ImageBox />
    <FileUploadBox />
    <AlertBox />
    <ModalBox />
    <!-- <NotificationBox /> -->
    <notifications group="top center" position="top center" classes="global-notification" />
    <notifications group="top right" position="top right" :close-on-click="false">
        <template #body="props">
            <div class="global-notification notification-closable">
                <div>
                    <div class="notification-closable-title">
                        {{ props.item.title }}
                    </div>
                    <div class="notification-closable-message" v-html="props.item.text" />
                </div>
                <div class="notification-closable-close" @click="closeNotification(props.item)">
                    <font-awesome-icon icon="close" />
                </div>
            </div>
        </template>
    </notifications>
</template>

<script lang="ts" setup>
import { onBeforeMount } from 'vue'
import { useAuthStore, init, injectLocalStores } from './stores'
import { useNotification } from '@kyvg/vue3-notification'

onBeforeMount(async () => {
    injectLocalStores()
    if (useAuthStore().isLoggedIn) {
        await init()
    }
})

const closeNotification = (item: any) => {
    useNotification().notify.close(item.id)
}
</script>

<style lang="scss">
@import url('./scss/global.scss');
</style>

<style scoped lang="scss">
.notification-closable {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.notification-closable-title {
    margin-top: 10px;
    font-size: 1.1em;
    font-weight: 800;
}
.notification-closable-message {
    margin: 10px 0;
}
.notification-closable-close {
    font-size: 18px;
    padding: 10px;
    cursor: pointer;
    font-weight: 600;
    color: #fff;
}
</style>
