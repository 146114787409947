import { computed, Ref, ref } from 'vue'
import { defineStore } from 'pinia'
import { usePayoutsStore } from '../payouts/store'
import { usePayinsStore } from '../payins/store'
import { Deposit, DepositFetchCollectionParams, DepositReference } from './types'
import { Client } from '@courseur/features/clients/types'
import { useEzioAPI } from '@courseur/services'
import { Payin } from '@courseur/features/payins/types'

export const useDepositsStore = defineStore('deposits', () => {
    const { api } = useEzioAPI()

    const payinsStore = usePayinsStore()
    const payoutsStore = usePayoutsStore()

    const deposits: Ref<DepositReference[]> = ref([])

    const getDeposits = computed(() => deposits.value.map((deposit) => (deposit.type === 'payin' ? payinsStore.all[deposit.id] : payoutsStore.all[deposit.id])))

    const getTotalAmount = computed((): number => {
        const goodOnes = getDeposits.value.filter((d) => {
            return d.status !== 'refused' || d.status !== 'canceled' || d.status !== 'failed'
        })

        return (
            goodOnes
                // NOTE: filter out payin direct_debit status different than settled,
                // and filter out payout transfer with different status than settled
                // .filter(
                //     (deposit) =>
                //         (deposit.__typename === 'Payin' && ((deposit.status === 'settled' && deposit.payinMethod === 'direct_debit') || (deposit.status === 'created' && deposit.payinMethod !== 'direct_debit'))) ||
                //         (deposit.__typename === 'Payout' && ((deposit.status === 'settled' && deposit.payoutMethod === 'transfer') || (deposit.status === 'created' && deposit.payoutMethod !== 'transfer')))
                // )
                .reduce((acc, curr) => {
                    let val = parseFloat(curr.amount.split(',').join('.').split(' ').join(''))
                    val = curr.__typename === 'Payout' ? -val : val
                    return acc + val
                }, 0)
        )
    })

    const fetchCollection = async (params: DepositFetchCollectionParams = null) => {
        try {
            const payins = await payinsStore.fetchCollection({
                client_id: params.client_id,
                agency_id: params.agency_id,
                payin_type: 'deposit'
            })

            const payouts = await payoutsStore.fetchCollection({
                client_id: params.client_id,
                agency_id: params.agency_id,
                payout_type: 'deposit_refund'
            })

            const sorted: Deposit[] = []
                .concat(payouts, payins)
                .sort((a, b) => {
                    return a.createdAt.localeCompare(b.createdAt)
                })
                .reverse()
            store(sorted)
        } catch (error) {
            //
        }
    }

    const fetchConfirmation = async (clientId: Client['id']) => {
        return await api.getFile('clients/' + clientId + '/deposit_confirmation')
    }

    const addProof = async (data: { id: string; proof: any }, type: 'Payout' | 'Payin', agencyId?: string) => {
        try {
            const params = Object.assign({}, data, { include: 'transactions,client', pagination: false })
            let response
            if (type === 'Payin') {
                response = await api.post('payins/' + data.id + '/proofs', params)
            } else {
                response = await payoutsStore.updatePayout(Object.assign(data, { agencyId }))
            }
            store([response.data])
        } catch (error) {
            //
        }
    }

    const removeProof = async (data: { id: string; proof_id: string }, type: 'Payout' | 'Payin') => {
        try {
            const params = Object.assign({}, data, { include: 'transactions,client', pagination: false })
            let response
            if (type === 'Payin') {
                response = await api.delete('payins/' + data.id + '/proofs', params)
            }

            //TODO: can we delete payout proof ?
            store([response.data])
        } catch (error) {
            //
        }
    }

    const markAsRefunded = async (payinId: Payin['id']) => {
        const response = await api.put('payins/' + payinId + '/refund')
        store([response.data])
    }

    const store = (sortedDeposits: Deposit[]) => {
        deposits.value = sortedDeposits.map((deposit) => {
            return {
                id: deposit.id,
                type: 'payinType' in deposit ? 'payin' : 'payout'
            }
        })
    }

    const reset = () => {
        deposits.value = []
    }

    return {
        totalAmount: getTotalAmount,
        deposits: getDeposits,
        fetchCollection,
        fetchConfirmation,
        addProof,
        removeProof,
        markAsRefunded,
        store,
        reset
    }
})
