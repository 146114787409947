<template>
    <div v-bind="useAttrs()" class="cs-button" :class="'ste-' + currentState" :disabled="disabled">
        <slot />
    </div>
</template>

<script lang="ts" setup>
import { useSlots, useAttrs, computed } from 'vue'

const slots = useSlots()
const props = withDefaults(
    defineProps<{
        state?: boolean
        stateNumber?: number
    }>(),
    { state: undefined }
)

const currentState = computed(() => {
    if (props.state !== undefined && !props.stateNumber) {
        return props.state ? 2 : 1
    } else if (props.state === undefined && props.stateNumber) {
        return props.stateNumber
    } else {
        return 1
    }
})
const disabled = computed(() => slots.default()[currentState.value - 1].props?.disabled)
</script>

<style lang="scss" scoped>
.cs-button {
    width: auto;
    :slotted(.c-button) {
        display: none;
    }
    :slotted(.c-button:disabled) {
        cursor: default;
    }
    &:disabled,
    &[disabled] {
        cursor: default;
    }
}
.ste-1 {
    :slotted(.c-button:nth-child(1)) {
        display: block;
    }
}

.ste-2 {
    :slotted(.c-button:nth-child(2)) {
        display: block;
    }
}

.ste-3 {
    :slotted(.c-button:nth-child(3)) {
        display: block;
    }
}

.ste-4 {
    :slotted(.c-button:nth-child(4)) {
        display: block;
    }
}
</style>
