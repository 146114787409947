import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { Agency, CreateAgencyParams, OnboardUser } from './types'
import { BaseError, objectify } from '@courseur/utils'
import dayjs from 'dayjs'
import { useEzioAPI } from '@courseur/services'

export const useAgenciesStore = defineStore('agencies', () => {
    const { api } = useEzioAPI()

    const current = ref<Agency>(null)
    const all = ref<{ [id: string]: Agency }>({})
    const indexByName = ref<Agency[]>([])
    const onboardUsers = ref<{ [id: string]: OnboardUser }>({})

    const init = () => {}

    const getActivesByName = computed(() => {
        return indexByName.value.slice().filter((agency) => !agency.deleted && agency.active)
    })

    const getByCreationDate = computed(() => {
        return indexByName.value.slice().sort((A, B) => {
            const dateA = dayjs(A.createdAt).valueOf()
            const dateB = dayjs(B.createdAt).valueOf()

            if (dateA < dateB) {
                return -1
            }
            if (dateA > dateB) {
                return 1
            }
            return 0
        })
    })

    const getByLastTransactionDate = computed(() => {
        return indexByName.value
            .slice()
            .filter((a) => a.active && a.lastTransactionAt)
            .sort((a, b) => {
                const dateA = dayjs(a.lastTransactionAt).valueOf()
                const dateB = dayjs(b.lastTransactionAt).valueOf()

                if (dateA < dateB) {
                    return -1
                }
                if (dateA > dateB) {
                    return 1
                }
                return 0
            })
    })

    const mainNavigationLabels = computed(() => {
        const id = current.value?.id
        console.log(id)
        return Object.assign(
            {
                home: 'Accueil',
                transactions: 'Courses',
                clients: 'Clients',
                employees: 'Collaborateurs',
                cards: 'Cartes'
            },
            all.value[id]?.menuLabels
        )
    })

    const businessInformations = async (siret: string) => {
        const response = await api.get('/businessinformations', { siret })
        return response.data
    }

    const fetchCollection = async () => {
        try {
            const response = await api.get('agencies', {
                pagination: false
                // include: 'company'
            })
            storeIndex(response.data)
        } catch (error) {
            //
        }
    }

    const fetch = async (id: string, include: string = null) => {
        const response = await api.get('agencies/' + id, {
            include: include || 'account,onboarding,company'
        })
        store([response.data])
        return response.data
    }

    const create = async (data: CreateAgencyParams) => {
        try {
            const response = await api.post(
                'agencies',
                Object.assign(data, {
                    include: 'account,onboarding,company'
                })
            )
            store([response.data])
            updateIndex(response.data)
            return response.data
        } catch (error: any) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message
            })
        }
    }

    const update = async (data: any) => {
        try {
            const payload = Object.assign({ include: 'company,onboarding' }, data)
            const response = await api.put('agencies/' + data.id, payload)
            store([response.data])
            updateIndex(response.data)
            return response.data
        } catch (error: any) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message
            })
        }
    }

    const createOrUpdate = async (data: any): Promise<Agency> => {
        if (data.id) {
            return await update(data)
        } else {
            return await create(data)
        }
    }

    const remove = async (id: string) => {
        const response = await api.delete('agencies/' + id)
        store([response.data])
        return response.data
    }

    const updateDetails = async (agencyId: string, details: any) => {
        const newDetails = Object.assign({}, JSON.parse(all.value[agencyId]?.details), details)
        return update({
            id: agencyId,
            details: JSON.stringify(newDetails)
        })
    }

    const submitKyc = async (agencyId: string, onlyAgency = false) => {
        const response = await api.post('agencies/' + agencyId + '/submit_kyc', {
            only_agency: onlyAgency
        })
        return response.data
    }
    const submitUserKyc = async (agencyId: string, userId: string) => {
        const response = await api.post('agencies/' + agencyId + '/users/' + userId + '/submit_kyc')
        return response.data
    }

    const fetchUsersCollection = async (agencyId: string, params?: { is_manager: boolean }) => {
        const payload = Object.assign({}, params, { pagination: false })
        const response = await api.get('agencies/' + agencyId + '/users', payload)
        storeUsers(response.data)
        return response.data
    }

    const fetchUser = async (agencyId: string, userId: string) => {
        const response = await api.get('agencies/' + agencyId + '/users/' + userId)
        storeUsers([response.data])
        return response.data
    }

    const addUser = async (agencyId: string, params: any) => {
        const response = await api.post('agencies/' + agencyId + '/users', params)
        storeUsers([response.data])

        // const response2 = await api.put('users/' + user.value.id + '/set_agency_id', {
        //         agency_id: id,
        //         include: 'account'
        //     })
        //     store(response.data)
        //     return response.data

        return response.data
    }

    const updateUser = async (agencyId: string, user: any) => {
        try {
            const response = await api.put('agencies/' + agencyId + '/users/' + user.id, user)
            storeUsers([response.data])
            return response.data
        } catch (error: any) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message
            })
        }
    }

    const requestLivenessUrl = async (agencyId: string, userId: string) => {
        const response = await api.post('agencies/' + agencyId + '/users/' + userId + '/request_liveness', {
            user_id: userId
        })
        return response.data
    }
    const forceLivenessStatus = async (agencyId: string, userId: string, score) => {
        const response = await api.put('agencies/' + agencyId + '/users/' + userId + '/force_liveness_status', {
            score
        })
        return response.data
    }

    const fetchManagers = async (agencyId: string) => {
        const response = await api.get('agencies/' + agencyId + '/users', {
            is_manager: true
        })
        return response.data
    }

    const setCurrent = async (agencyId: string) => {
        await fetch(agencyId)
        current.value = all.value[agencyId]
    }

    const updateIndex = (agency: Agency) => {
        const temp = indexByName.value.slice()
        const idx = temp.findIndex((c) => agency.id === c.id)
        if (idx > -1) {
            temp[idx] = agency
        } else {
            temp.push(agency)
        }
        temp.sort(sortByName)
        indexByName.value = temp
    }
    const storeIndex = (agencies: Agency[]) => {
        indexByName.value = agencies
        indexByName.value.sort(sortByName)
    }
    const store = (agencies: Agency[]) => {
        const ags = agencies.map((a) => Object.assign({}, all.value[a.id], a))
        all.value = Object.assign({}, all.value, objectify(ags))
    }
    const storeUsers = (users: OnboardUser[]) => {
        const ags = users.map((a) => Object.assign({}, onboardUsers.value[a.id], a))
        onboardUsers.value = Object.assign({}, onboardUsers.value, objectify(ags))
    }

    const reset = () => {
        all.value = {}
    }

    const sortByName = (A, B) => {
        const nameA = (A.displayName || A.legalName)
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')

        const nameB = (B.displayName || B.legalName)
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')

        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }
        return 0
    }

    return {
        current,
        all,
        byCreationDate: getByCreationDate,
        byLastTransactionDate: getByLastTransactionDate,
        byName: indexByName,
        activesByName: getActivesByName,
        onboardUsers,
        mainNavigationLabels,
        init,
        businessInformations,
        fetch,
        fetchCollection,
        createOrUpdate,
        update,
        remove,
        updateDetails,
        submitKyc,
        submitUserKyc,
        setCurrent,
        fetchManagers,
        addUser,
        requestLivenessUrl,
        forceLivenessStatus,
        fetchUser,
        updateUser,
        fetchUsersCollection,
        reset,
        store
    }
})
