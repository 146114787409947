import {
    Avatar,
    CButton,
    CSButton,
    CBackButton,
    CRouterLink,
    CLink,
    FeatherIcon
} from '@courseur/ui'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faAngleDown,
    faFilePdf,
    faBan,
    faLock,
    faSlash,
    faCircleCheck,
    faArrowUpFromBracket,
    faArrowsDownToLine,
    faAngleUp,
    faBell,
    faCog,
    faEdit,
    faEyeSlash,
    faPhoneAlt,
    faHome,
    faIdCard,
    faPlus,
    faIdBadge,
    faShoppingCart,
    faStoreAlt,
    faUserFriends,
    faCreditCard,
    faClipboardList,
    faTrashAlt,
    faTimes,
    faEnvelope,
    faChevronRight,
    faChevronLeft,
    faQuestion,
    faArrowLeft,
    faRecycle,
    faExclamationCircle,
    faComment,
    faUserTie,
    faFileInvoice,
    faRightLeft,
    faQuestionCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { App } from 'vue'

import Centered from '@courseur/ui/layouts/Centered.vue'
import Admin from '@/layouts/Admin.vue'
import Organization from '@/layouts/Organization.vue'

export const initComponents = (app: App) => {
    app.component('avatar', Avatar)
    app.component('CButton', CButton)
    app.component('CSButton', CSButton)
    app.component('CLink', CLink)
    app.component('CRouterLink', CRouterLink)
    app.component('CBackButton', CBackButton)
    app.component('FeatherIcon', FeatherIcon)

    library.add(
        faAngleDown,
        faFilePdf,
        faBan,
        faLock,
        faSlash,
        faCircleCheck,
        faArrowUpFromBracket,
        faArrowsDownToLine,
        faAngleUp,
        faBell,
        faCog,
        faEdit,
        faEyeSlash,
        faPhoneAlt,
        faIdCard,
        faHome,
        faPlus,
        faIdBadge,
        faShoppingCart,
        faStoreAlt,
        faUserFriends,
        faCreditCard,
        faClipboardList,
        faTrashAlt,
        faTimes,
        faEnvelope,
        faChevronRight,
        faChevronLeft,
        faQuestion,
        faArrowLeft,
        faRecycle,
        faExclamationCircle,
        faComment,
        faUserTie,
        faFileInvoice,
        faRightLeft,
        faQuestionCircle
    )
    app.component('font-awesome-icon', FontAwesomeIcon)
}

export const initLayouts = async (app: App) => {
    app.component('Centered', Centered)
    app.component('Admin', Admin)
    app.component('Organization', Organization)
}
