import { ref, Ref } from 'vue'
import { useBase64 } from '@courseur/utils'
import LogoPdf from '@courseur/assets/images/logo-pdf.png'

let instance: any = null

const createInstance = () => {
    const accept = 'image/png,image/gif,image/jpeg,application/pdf'
    const current: Ref<number> = ref(0)
    const visible: Ref<boolean> = ref(false)
    const preview: Ref<string> = ref(null)
    const maxSize = 3000

    let onSelectCallback: (file: string) => unknown = null

    const show = async (callback: (file: string) => unknown) => {
        visible.value = true
        preview.value = null
        onSelectCallback = callback
    }

    const dismiss = () => {
        visible.value = false
        onSelectCallback(null)
    }

    const onSelectFile = async (file: any) => {
        const fileType = file.type

        let b64File
        if (fileType.indexOf('image') > -1) {
            const { convertImageToB64 } = useBase64()
            b64File = await convertImageToB64(file, maxSize)
            preview.value = b64File
        } else {
            const { convertFileToB64 } = useBase64()
            b64File = await convertFileToB64(file)
            preview.value = LogoPdf
        }

        await onSelectCallback(b64File)
        visible.value = false
    }

    return {
        accept,
        preview,
        current,
        visible,
        onSelectFile,
        show,
        dismiss
    }
}

export const useFileUploadBox = () => {
    if (instance) {
        return {
            fileUploadBox: instance
        }
    }
    instance = createInstance()
    return {
        fileUploadBox: instance
    }
}
