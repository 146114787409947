<template>
    <div v-if="values && values.length" class="notification-list">
        <div v-for="notification in props.values" :key="notification.id">
            <NotificationItem :value="notification" />
        </div>
    </div>
    <div v-else class="empty">pas de notification</div>
</template>

<script lang="ts" setup>
import { Alert } from '@courseur/features/alerts/type'
import { defineAsyncComponent } from 'vue'
const NotificationItem = defineAsyncComponent(() => import('./NotificationItem.vue'))

const props = withDefaults(
    defineProps<{
        values?: Alert[]
    }>(),
    {
        values: () => [],
    }
)
</script>

<style lang="scss" scoped>
.notification-list {
    overflow-y: scroll;
    height: 300px;
}

.empty {
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
</style>
