<template>
    <svg class="feather">
        <use :href="img + '#' + props.name" />
    </svg>
</template>

<script lang="ts" setup>
import { onBeforeMount, ref } from 'vue'

const props = withDefaults(
    defineProps<{
        name: string
    }>(),
    {
        name: 'circle'
    }
)

const img = ref('')

onBeforeMount(async () => {
    img.value = (await import('feather-icons/dist/feather-sprite.svg')).default
})
</script>

<style lang="scss" scoped>
.feather {
    width: 1.2em;
    height: 1.2em;
    stroke: currentColor;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
}
</style>
