import axios, { AxiosResponse } from 'axios'
import { HttpError } from './HttpError'
import { HttpConfig } from './types/HttpConfig'
import { HttpErrorParams } from './types/HttpErrorParams'

export class Http {
    protected config: HttpConfig = null

    /**
     * Our 4 main http methods
     * they all redirect to one submit method
     */
    public get(route: string, data: any = {}, options: any = {}) {
        return this.submit(
            {
                method: 'get',
                url: route,
                params: data
            },
            options
        )
    }

    public getFile(route: string, data: any = {}, options: any = {}) {
        return this.submit(
            {
                method: 'get',
                responseType: 'blob',
                url: route,
                params: data
            },
            options
        )
    }

    public post(route: string, data: any = {}, options: any = {}) {
        return this.submit(
            {
                method: 'post',
                url: route,
                data
            },
            options
        )
    }

    public put(route: string, data: any = {}, options: any = {}) {
        return this.submit(
            {
                method: 'put',
                url: route,
                data
            },
            options
        )
    }

    public delete(route: string, data: any = {}, options: any = {}) {
        return this.submit(
            {
                method: 'delete',
                url: route,
                data
            },
            options
        )
    }

    public custom(params: any, headers: any = null) {
        params.headers = headers || this.getRequestHeaders()
        return axios.request(params)
    }

    protected getRequestHeaders() {
        const headers = {
            Accept: 'application/json',
            'Cache-Control': 'no-store; no-cache; must-revalidate'
        }
        return headers
    }

    protected getBaseUrl(options: any): string {
        const version = options.apiVersion !== undefined ? options.apiVersion : this.config.version
        return this.config.url + version + '/'
    }

    protected async onSuccess(response: AxiosResponse): Promise<any> {
        console.warn('You Should override this HTTP.onSuccess method')
        return response.data
    }

    protected onError(error: HttpError): HttpError {
        return error
    }

    private updateRequestParams(requestParams: any, options: any) {
        const rp: any = Object.assign(requestParams, {
            baseURL: this.getBaseUrl(options),
            timeout: 60000,
            headers: this.getRequestHeaders()
        })
        if (!rp.params) {
            rp.params = {}
        }
        rp.params.stamp = new Date().getMinutes()
        return rp
    }
    /**
     * Submit our request
     */
    private async submit(requestParams: any = {}, options: any = {}) {
        const params = this.updateRequestParams(requestParams, options)

        try {
            const response: any = await axios.request(params)
            if (response.data.error) {
                throw new HttpError({
                    isAppError: true,
                    status: response.status,
                    message: response.data.error
                })
            }

            if (requestParams.responseType === 'blob' || requestParams.responseType === 'application/pdf') {
                // file to download
                return response.data
            }

            if (response.headers['content-type'].indexOf('application/json') < 0) {
                throw new HttpError({
                    isServerError: true,
                    status: response.status,
                    message: 'Erreur Server'
                })
            }
            const r = await this.onSuccess(response)
            console.groupCollapsed('Request Success:', params.method.toUpperCase(), params.baseURL + params.url)
            console.group('Params')
            console.table(params.method === 'get' ? params.params : params.data)
            console.groupEnd()
            console.group('Response')
            console.log(r)
            console.groupEnd()
            console.groupEnd()
            return r
        } catch (error) {
            let r = this.createError(error)
            r = await this.onError(r)
            console.group('Request Error:', params.method.toUpperCase(), params.baseURL + params.url)
            console.group('Params')
            console.table(params.method === 'get' ? params.params : params.data)
            console.groupEnd()
            console.group('Response')
            console.warn(r.message)
            console.groupEnd()
            console.groupEnd()
            throw r
        }
    }

    private createError(error: any): HttpError {
        if (error.name === 'HttpError') {
            // already created
            return error
        }
        const params: HttpErrorParams = {}
        if (error.response) {
            // Request made and server responded
            params.status = error.response.status
            params.isServerError = true

            if (error.response.data) {
                params.message = !error.response.data.errors ? error.response.data : error.response.data.errors.full_messages ? error.response.data.errors.full_messages[0] : error.response.data.errors[0]
            } else {
                params.message = error.message
            }
        } else if (error.request) {
            // The request was made but no response was received
            params.isNetworkError = true
            params.message = 'Erreur de communication avec le serveur. Veuillez vérifier que vous êtes bien connecté.'
        } else {
            console.log('has no response, no request')
            params.isUnknownError = true
            params.message = 'Erreur inconnue'
        }
        return new HttpError(params)
    }
}
